var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c("v-data-table", {
            staticClass: "elevation-1 mk-poppins-text submission-table",
            attrs: {
              headers: !_vm.$language ? _vm.headers : _vm.headersBN,
              items: _vm.latestSubmissions,
              "no-data-text": "No submissions yet!",
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.file_path",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "orange--text px-12",
                        attrs: { color: "orange lighten-5", depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.download(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$language
                                ? "See file list"
                                : "ফাইল লিস্ট দেখুন"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "70%" },
                        model: {
                          value: _vm.submissionList,
                          callback: function ($$v) {
                            _vm.submissionList = $$v
                          },
                          expression: "submissionList",
                        },
                      },
                      [
                        _vm.submissionList
                          ? _c("m-project-submission-list", {
                              attrs: {
                                urls: _vm.urls,
                                "step-submissions": _vm.stepSubmissions,
                                "student-id": item.student_id,
                                "project-id": item.object_id,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.submissionList = false
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.grade",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "mt-4" },
                      [
                        _c("v-text-field", {
                          staticStyle: { "max-width": "150px" },
                          attrs: {
                            id: item.student_id,
                            placeholder: !_vm.$language ? "Grade" : "গ্রেড",
                            type: "number",
                            dense: "",
                            suffix: `/${_vm.shareDetail.total_score}`,
                            max: _vm.shareDetail.total_score,
                            solo: "",
                            rules: [
                              _vm.required,
                              _vm.digitsOnly,
                              (v) =>
                                _vm.maxValue(
                                  v,
                                  Number(_vm.shareDetail.total_score),
                                  "Grades"
                                ),
                            ],
                          },
                          on: {
                            input: function ($event) {
                              return _vm.adjustInput(
                                _vm.grades[item.student_id],
                                item.student_id
                              )
                            },
                            paste: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.grades[item.student_id],
                            callback: function ($$v) {
                              _vm.$set(_vm.grades, item.student_id, _vm._n($$v))
                            },
                            expression: "grades[item.student_id]",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.action",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text",
                        attrs: {
                          color: "blue lighten-5",
                          rounded: "",
                          depressed: "",
                          loading: _vm.grading[item.student_id],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.grade_submission(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(!_vm.$language ? "Save" : "সেভ করুন") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }