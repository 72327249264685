<template>
  <v-container>
    <PageTitle :page-title="project.title">
      <template v-slot:left>
        <v-btn icon @click="$router.replace({ name: 'projects' })">
          <v-icon class="mr-2">arrow_back</v-icon>
        </v-btn>
      </template>
    </PageTitle>
    <v-container>
      <!--      <m-project-expansion-content-->
      <!--        :project-id="project_id"-->
      <!--        @grade="submit_grade"-->
      <!--        batch-id="random"-->
      <!--      >-->
      <!--      </m-project-expansion-content>-->
      <template v-if="!renderNow">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="networkError"
        :message="networkErrorNoContentMessage"
      ></no-content-component>
      <no-content-component
        v-else-if="batches?.length == 0"
        :message="noProjectSubmissionMessage"
      ></no-content-component>
      <v-expansion-panels inset>
        <v-expansion-panel v-for="(batch, i) in batches" :key="i">
          <v-expansion-panel-header>{{
            batch.batch_title
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <ProjectSubmissions
              :project-id="project_id"
              :batch-id="batch.batch_id"
              :submissions="batch.submissions"
              :share-detail="batch"
              :grading="grading"
              @grade="submit_grade"
            >
            </ProjectSubmissions>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<script>
import ProjectSubmissions from "#ecf/project/components/MProjectSubmissions";
import projectService from "#ecf/project/services/ProjectWorkService";
import NoContentComponent from "/global/components/NoContentComponent";
import _ from "lodash";

export default {
  name: "ProjectGradesPage",
  components: { ProjectSubmissions, NoContentComponent },
  props: {
    courseId: String,
    project_id: String,
  },
  data() {
    return {
      project: { title: "" },
      submissions: {},
      batchesData: [],
      grades: {},
      grading: {},
      renderNow: false,
      networkError: false,
    };
  },
  computed: {
    batches() {
      const bs = this.batchesData.map((b) => {
        if (!this.submissions[b.batch_id]) {
          return false;
        } else {
          const clone = { ...b };
          clone.submissions = this.submissions[b.batch_id];
          return clone;
        }
      });
      return _.compact(bs);
    },
    noProjectSubmissionMessage() {
      return this.$language
        ? "বর্তমানে এই প্রজেক্ট কোনো ব্যাচ এর সাথে শেয়ার করা নেই"
        : "Currently the project is not being shared with any batches";
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Your projects are currently not available"
        : "বর্তমানে আপনার প্রজেক্ট সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      this.renderNow = true;
      return;
    }
    this.renderNow = false;
    await this.fetchProject();
    await this.fetchSubmissions();
    this.renderNow = true;
  },
  methods: {
    async download() {
      try {
        window.open(this.project.url);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },

    async fetchProject() {
      try {
        this.project = await projectService.get(this.project_id, this.courseId);
        this.batchesData = await projectService.getSharedBatches(
          this.project.object_id,
          this.courseId
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchSubmissions() {
      try {
        const submissions = await projectService.getSubmissions(
          this.project_id,
          this.courseId,
          this.batch_id
        );
        this.submissions = submissions
          .filter((s) => s.batch_id)
          .reduce((s_map, s) => {
            if (s_map[s.batch_id]) {
              s_map[s.batch_id].push(s);
            } else {
              s_map[s.batch_id] = [s];
            }
            return s_map;
          }, {});
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async submit_grade(payload) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.$set(this.grading, payload.submission.student_id, true);
        await projectService.grade(
          this.project_id,
          payload.submission.course_id,
          payload.submission.batch_id,
          payload.submission.student_id,
          payload.score
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.grading, payload.submission.student_id, false);
      }
    },
  },
};
</script>
