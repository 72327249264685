<template>
  <v-card>
    <div class="d-flex justify-end">
      <v-btn icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
    </div>
    <v-card-title class="justify-center" style="margin-top: -20px;">{{
      !$language ? "Student's Submissions" : "শিক্ষার্থীর জমাকৃত প্রজেক্ট"
    }}</v-card-title>
    <v-card-text>
      <v-tabs v-model="currentTab" class="pl-10 py-10">
        <v-tab v-for="(i, j) in stepSubmissions" :key="j">
          {{ !$language ? "Step" : "স্টেপ" }} {{ j + 1 }}
          {{ !$language ? "submissions" : "এর জমা" }}</v-tab
        >
        <v-tab>{{ !$language ? "Overall submissions" : "সকল জমা" }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(i, j) in stepSubmissions" :key="j">
          <v-row v-for="(url, index) in i.url" :key="index">
            <v-col class="pl-14"
              >{{ index + 1 }}. {{ getFileName(url, true) }}</v-col
            >
            <v-col
              ><v-btn
                color="primary"
                class="px-12"
                depressed
                @click="
                  $router.push({
                    name: 'project-assessment',
                    params: {
                      projectId,
                      studentId,
                      index: `step${j}-${index}`
                    }
                  })
                "
                ><v-icon left>visibility</v-icon
                >{{ !$language ? "Preview" : "প্রিভিউ" }}</v-btn
              ></v-col
            >
          </v-row>
          <div style="height: 30px;"></div>
        </v-tab-item>
        <v-tab-item>
          <v-row v-for="(url, index) in urls" :key="index">
            <v-col class="pl-14"
              >{{ index + 1 }}. {{ getFileName(url, false) }}</v-col
            >
            <v-col
              ><v-btn
                color="primary"
                class="px-12"
                depressed
                @click="
                  $router.push({
                    name: 'project-assessment',
                    params: { projectId, studentId, index: `whole-${index}` }
                  })
                "
                ><v-icon left>visibility</v-icon
                >{{ !$language ? "Preview" : "প্রিভিউ" }}</v-btn
              ></v-col
            >
          </v-row>
          <div style="height: 30px;"></div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MProjectSubmissionList",
  props: {
    studentId: { type: String, default: "" },
    projectId: { type: String, default: "" },
    urls: {
      type: Array,
      required: true
    },
    stepSubmissions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentTab: 0
    };
  },
  methods: {
    getFileName(url, isStep) {
      let s3Name = decodeURIComponent(
        url
          .split("?")[0]
          .split("/")
          .pop()
      );
      if (isStep === true) {
        let splitted = s3Name.split("_");
        splitted.shift();
        return splitted.join("_");
      }
      return s3Name;
    },
    download(url) {
      window.open(url);
    }
  }
};
</script>

<style></style>
