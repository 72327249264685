// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".submission-table tr:hover {\n  background-color: inherit !important;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
