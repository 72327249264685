var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-title",
        {
          staticClass: "justify-center",
          staticStyle: { "margin-top": "-20px" },
        },
        [
          _vm._v(
            _vm._s(
              !_vm.$language
                ? "Student's Submissions"
                : "শিক্ষার্থীর জমাকৃত প্রজেক্ট"
            )
          ),
        ]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            {
              staticClass: "pl-10 py-10",
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _vm._l(_vm.stepSubmissions, function (i, j) {
                return _c("v-tab", { key: j }, [
                  _vm._v(
                    " " +
                      _vm._s(!_vm.$language ? "Step" : "স্টেপ") +
                      " " +
                      _vm._s(j + 1) +
                      " " +
                      _vm._s(!_vm.$language ? "submissions" : "এর জমা")
                  ),
                ])
              }),
              _c("v-tab", [
                _vm._v(
                  _vm._s(!_vm.$language ? "Overall submissions" : "সকল জমা")
                ),
              ]),
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _vm._l(_vm.stepSubmissions, function (i, j) {
                return _c(
                  "v-tab-item",
                  { key: j },
                  [
                    _vm._l(i.url, function (url, index) {
                      return _c(
                        "v-row",
                        { key: index },
                        [
                          _c("v-col", { staticClass: "pl-14" }, [
                            _vm._v(
                              _vm._s(index + 1) +
                                ". " +
                                _vm._s(_vm.getFileName(url, true))
                            ),
                          ]),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-12",
                                  attrs: { color: "primary", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        name: "project-assessment",
                                        params: {
                                          projectId: _vm.projectId,
                                          studentId: _vm.studentId,
                                          index: `step${j}-${index}`,
                                        },
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("visibility"),
                                  ]),
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Preview" : "প্রিভিউ"
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _c("div", { staticStyle: { height: "30px" } }),
                  ],
                  2
                )
              }),
              _c(
                "v-tab-item",
                [
                  _vm._l(_vm.urls, function (url, index) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c("v-col", { staticClass: "pl-14" }, [
                          _vm._v(
                            _vm._s(index + 1) +
                              ". " +
                              _vm._s(_vm.getFileName(url, false))
                          ),
                        ]),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "px-12",
                                attrs: { color: "primary", depressed: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "project-assessment",
                                      params: {
                                        projectId: _vm.projectId,
                                        studentId: _vm.studentId,
                                        index: `whole-${index}`,
                                      },
                                    })
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("visibility"),
                                ]),
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Preview" : "প্রিভিউ")
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c("div", { staticStyle: { height: "30px" } }),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }