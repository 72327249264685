<template>
  <div>
    <v-form ref="form" v-model="formValid">
      <v-data-table
        :headers="!$language ? headers : headersBN"
        :items="latestSubmissions"
        class="elevation-1 mk-poppins-text submission-table"
        no-data-text="No submissions yet!"
        hide-default-footer
      >
        <template v-slot:item.file_path="{ item }">
          <v-btn
            color="orange lighten-5"
            class="orange--text px-12"
            depressed
            @click="download(item)"
          >
            {{ !$language ? "See file list" : "ফাইল লিস্ট দেখুন" }}
          </v-btn>
          <v-dialog v-model="submissionList" width="70%">
            <m-project-submission-list
              v-if="submissionList"
              :urls="urls"
              :step-submissions="stepSubmissions"
              :student-id="item.student_id"
              :project-id="item.object_id"
              @close="submissionList = false"
            ></m-project-submission-list>
          </v-dialog>
        </template>
        <!--      <template v-slot:item.upload_date="{ item }">-->
        <!--        <span>{{-->
        <!--          new Date(item.upload_date * 1000).toLocaleDateString()-->
        <!--        }}</span>-->
        <!--      </template>-->
        <template v-slot:item.grade="{ item }">
          <v-row class="mt-4">
            <v-text-field
              :id="item.student_id"
              v-model.number="grades[item.student_id]"
              style="max-width: 150px"
              :placeholder="!$language ? 'Grade' : 'গ্রেড'"
              type="number"
              dense
              :suffix="`/${shareDetail.total_score}`"
              :max="shareDetail.total_score"
              solo
              @input="adjustInput(grades[item.student_id], item.student_id)"
              @paste.prevent
              :rules="[
                required,
                digitsOnly,
                v => maxValue(v, Number(shareDetail.total_score), 'Grades')
              ]"
            ></v-text-field>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            color="blue lighten-5"
            class="primary--text"
            rounded
            depressed
            :loading="grading[item.student_id]"
            @click="grade_submission(item)"
            >{{ !$language ? "Save" : "সেভ করুন" }}
          </v-btn>
        </template>
      </v-data-table>
    </v-form>
  </div>
</template>

<script>
import projectService from "#ecf/project/services/ProjectWorkService";
import MProjectSubmissionList from "#ecf/project/components/MProjectSubmissionList";
import { FieldValidations } from "/global/utils/validations.js";
import { sortBy, assign } from "lodash";

const TABLE_HEADERS = [
  {
    text: "Name",
    value: "user_name",
    width: "25%"
  },
  {
    text: "Content",
    value: "file_path",
    width: "25%"
  },
  {
    text: "Submit Date",
    value: "upload_date",
    align: "left",
    width: "25%"
  },
  {
    text: "Grade",
    value: "grade",
    align: "left",
    width: "25%"
  },
  {
    text: "",
    value: "action",
    sortable: false
  }
];

const TABLE_HEADERS_BN = [
  {
    text: "নাম",
    value: "user_name",
    width: "25%"
  },
  {
    text: "বিষয়বস্তু",
    value: "file_path",
    width: "25%"
  },
  {
    text: "জমাদানের তারিখ",
    value: "upload_date",
    align: "left",
    width: "25%"
  },
  {
    text: "গ্রেড",
    value: "grade",
    align: "left",
    width: "25%"
  },
  {
    text: "",
    value: "action",
    sortable: false
  }
];

export default {
  name: "MProjectExpansionContent",
  components: { MProjectSubmissionList },
  mixins: [FieldValidations],
  props: {
    batchId: { type: String, default: "" },
    projectId: { type: String, default: "" },
    submissions: { type: Array, default: undefined },
    shareDetail: { type: Object, default: () => ({}) },
    grading: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ["$courseId"],
  data() {
    return {
      headers: TABLE_HEADERS,
      headersBN: TABLE_HEADERS_BN,
      formValid: undefined,
      grades: this.map_submissions_to_grades(this.submissions),
      submissionList: false,
      urls: [],
      stepSubmissions: []
    };
  },
  computed: {
    latestSubmissions() {
      return this.getLatestSubmissions();
    },
  },
  watch: {
    formValid: {
      handler(value) {
        if(value && this.showBanner) this.showBanner = false;
      }
    }
  },
  methods: {
    getLatestSubmissions() {
      let ordered = sortBy(this.submissions, "upload_date").reverse();
      return Object.values(
        ordered.reduce(
          (acc, curr) =>
            acc[curr.student_id]
              ? acc
              : assign(acc, { [curr.student_id]: curr }),
          {}
        )
      );
    },
    map_submissions_to_grades(submissions) {
      if (!submissions) return {};
      return submissions.reduce((acc, curr) => {
        this.$set(acc, curr.student_id, curr.grade);
        return acc;
      }, {});
    },
    adjustInput(data, student_id) {
      let score = parseInt(data);
      if (isNaN(score)) score = "";
      document.getElementById(student_id).value = score;
    },
    async download(submission) {
      try {
        const submissionDetails = await projectService.getSubmission(
          this.$courseId(),
          this.projectId,
          submission.student_id
        );
        this.submissionList = true;
        this.stepSubmissions = submissionDetails.step_submissions;
        this.urls = Array.isArray
          ? [...submissionDetails.submissions[0].url]
          : [submissionDetails.submissions[0].url];
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchSubmissions() {
      try {
        this.submissions = await projectService.getSubmissions(
          this.projectId,
          this.course_id,
          this.batchId
        );
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.message]);
      }
    },
    async grade_submission(submission) {
      if (!this.formValid) {
        return;
      }
      this.$emit("grade", {
        submission,
        score: this.grades[submission.student_id]
      });
    }
  }
};
</script>

<style lang="scss">
.submission-table {
  tr:hover {
    background-color: inherit !important;
  }
}
</style>
