var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitle", {
        attrs: { "page-title": _vm.project.title },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({ name: "projects" })
                      },
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v("arrow_back"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-container",
        [
          !_vm.renderNow
            ? [
                _c("div", { attrs: { id: "loading-wrapper" } }, [
                  _c("div", { attrs: { id: "loading-text" } }, [
                    _vm._v("LOADING"),
                  ]),
                  _c("div", { attrs: { id: "loading-content" } }),
                ]),
              ]
            : _vm.networkError
            ? _c("no-content-component", {
                attrs: { message: _vm.networkErrorNoContentMessage },
              })
            : _vm.batches?.length == 0
            ? _c("no-content-component", {
                attrs: { message: _vm.noProjectSubmissionMessage },
              })
            : _vm._e(),
          _c(
            "v-expansion-panels",
            { attrs: { inset: "" } },
            _vm._l(_vm.batches, function (batch, i) {
              return _c(
                "v-expansion-panel",
                { key: i },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(_vm._s(batch.batch_title)),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("ProjectSubmissions", {
                        attrs: {
                          "project-id": _vm.project_id,
                          "batch-id": batch.batch_id,
                          submissions: batch.submissions,
                          "share-detail": batch,
                          grading: _vm.grading,
                        },
                        on: { grade: _vm.submit_grade },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }